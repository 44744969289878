.about__bg {
  background: rgb(237, 237, 240);
  background: linear-gradient(
    90deg,
    rgba(237, 237, 240, 1) 0%,
    rgba(224, 224, 231, 1) 12%,
    rgba(207, 228, 227, 1) 50%,
    rgba(211, 227, 231, 1) 100%
  );
  
}


.about__missions_img{
  position:absolute;
  width:100%;
 height:60vh;
 border-top-right-radius:1.5rem;
 border-bottom-right-radius:1.5rem;
 object-fit:cover;
}

@media screen and (max-width:650px) {
  .about__missions_img{
    border-top-right-radius:1.5rem;
    border-top-left-radius:1.5rem;
    border-bottom-right-radius:0;
  }
}