* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

.splide__arrows {
  position: absolute;
  bottom: -15%;
  right: 50%;
}

.splide__arrow {
  background: #aaaaff !important;
  width: 60px !important;
  height: 60px !important;
}

.splide {
  width: 90%;
}

.splide__arrow svg {
  transform: rotateY(180deg) !important;
}

.splide__arrow--prev svg {
  transform: rotateY(360deg) !important;
}

.portfolio-splide .splide__arrows {
  position: absolute;
}

.hero_img {
  background: url("./assets/homepage.webp");
  background-repeat: no-repeat;
}

@media screen and (max-width: 640px) {
  .splide__arrow {
    width: 40px !important;
    height: 40px !important;
  }
}
